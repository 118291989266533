<template>
  <v-sheet
    color="secondary lighten-1"
    class="d-flex align-center justify-center px-4"
    height="100%"
  >
    <v-card
      color="primary darken-2"
      class="mt-n6 white--text pa-6"
      max-width="500px"
      elevation="6"
    >
      <v-card-title class="white--text d-block text-center font-weight-medium">
        Recuperação de Senha
      </v-card-title>
      <v-card-text class="white--text text-center">
        Digite aqui a nova senha para acessar o sistema Tesys com seu usuário.
      </v-card-text>

      <v-form
        ref="passwordRecoveryForm"
        @submit.prevent="changePassword()"
        class="mt-2"
      >
        <v-text-field
          v-model="recovery.password"
          label="Nova Senha *"
          color="primary"
          class="white"
          filled
          hide-details="auto"
          :rules="[$rules.required, $rules.passwordMin]"
          :disabled="loadingSave"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
        />
        <v-text-field
          label="Confirmação da nova senha *"
          color="primary"
          class="white mt-4"
          filled
          hide-details="auto"
          :disabled="loadingSave"
          :append-icon="showRepeat ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showRepeat = !showRepeat"
          :type="showRepeat ? 'text' : 'password'"
          :rules="[
            $rules.required,
            $rules.equalPassword(() => recovery.password),
          ]"
        />
        <div class="d-flex justify-end mt-6">
          <v-btn color="success" type="submit">
            Definir nova senha
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "RecoverPassword",

  data: () => ({
    loading: false,
    loadingSave: false,
    showPassword: false,
    showRepeat: false,
    confirmPassword: false,
    recovery: {
      password: "",
    },
  }),
  methods: {
    changePassword() {
      if (!this.$refs.passwordRecoveryForm.validate()) {
        this.$showMessage("warning", "Preencha os campos corretamente");
      } else {
        this.loading = true;
        this.$axios
          .put("/recover-password", {
            code: this.$route.params.code,
            new_password: this.recovery.password,
          })
          .then(() => {
            this.$showMessage("success", "Senha alterada com sucesso");
            this.$router.replace({ name: "app" });
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 404:
                  this.$showMessage(
                    "warning",
                    "Solicitação de recuperação de senha não encontrada"
                  );
                  return;
                case 408:
                  this.$showMessage(
                    "warning",
                    "Este link para recuperação da senha expirou"
                  );
                  return;
                case 409:
                  this.$showMessage(
                    "warning",
                    "A nova senha é igual a sua senha antiga"
                  );
                  return;
                case 410:
                  this.$showMessage(
                    "warning",
                    "Este link para recuperação de senha já foi utilizado"
                  );
                  return;
              }
            }
            this.$showMessage("error", "Erro inesperado na alteração de senha");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
